import React, { useState, useEffect } from 'react';
import {
  LineChart, Line, BarChart, Bar, RadarChart, Radar, PieChart, Pie,
  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
  PolarGrid, PolarAngleAxis, PolarRadiusAxis, Area, AreaChart
} from 'recharts';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import {
  ThemeProvider, createTheme, styled, alpha
} from '@mui/material/styles';
import {
  AppBar, Toolbar, Typography, Container, Grid, Paper, Box,
  Tabs, Tab, IconButton, useMediaQuery, CssBaseline, Fab,
  Drawer, List, ListItem, ListItemIcon, ListItemText
} from '@mui/material';
import {
  Brightness4, Brightness7, Print as PrintIcon, Menu as MenuIcon,
  Dashboard, TrendingUp, CompareArrows, AttachMoney, Person, ArrowUpward
} from '@mui/icons-material';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.spacing(2),
  transition: 'all 0.3s ease-in-out',
  background: alpha(theme.palette.background.paper, 0.8),
  backdropFilter: 'blur(10px)',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[10],
  },
}));

const AnimatedTypography = styled(motion.div)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const FloatingFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
}));

function IRUTOMOReport() {
  const [darkMode, setDarkMode] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:600px)');

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: '#3f51b5',
      },
      secondary: {
        main: '#f50057',
      },
      background: {
        default: darkMode ? '#121212' : '#f5f5f5',
        paper: darkMode ? '#1e1e1e' : '#ffffff',
      },
    },
    typography: {
      fontFamily: "'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      h2: {
        fontSize: '2.5rem',
        fontWeight: 700,
        marginBottom: '1rem',
        background: '-webkit-linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      },
      h4: {
        fontSize: '2rem',
        fontWeight: 600,
        marginBottom: '0.5rem',
      },
      h5: {
        fontSize: '1.5rem',
        fontWeight: 500,
        marginBottom: '0.5rem',
      },
      body1: {
        fontSize: '1rem',
        lineHeight: 1.7,
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            background: 'rgba(63, 81, 181, 0.8)',
            backdropFilter: 'blur(10px)',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            fontSize: '1rem',
            fontWeight: 500,
          },
        },
      },
    },
  });

  const toggleDarkMode = () => setDarkMode(!darkMode);
  const handlePrint = () => window.print();
  const handleTabChange = (event, newValue) => setTabValue(newValue);
  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  // データ（実際のデータに置き換えてください）
  const visitorData = [
    { year: 2019, visitors: 558 },
    { year: 2020, visitors: 48 },
    { year: 2021, visitors: 2 },
    { year: 2022, visitors: 35 },
    { year: 2023, visitors: 300 },
    { year: 2024, visitors: 450 },
    { year: 2025, visitors: 600 },
  ];

  const marketSizeData = [
    { year: 2023, global: 2.5, japan: 30 },
    { year: 2025, global: 3.5, japan: 50 },
    { year: 2027, global: 5.0, japan: 75 },
    { year: 2030, global: 7.8, japan: 100 },
  ];

  const competitorData = [
    { name: 'IRUTOMO', language: 90, guide: 80, preTravelInteraction: 95, customTour: 85, globalExpansion: 50 },
    { name: 'Competitor A', language: 70, guide: 60, preTravelInteraction: 50, customTour: 70, globalExpansion: 80 },
    { name: 'Competitor B', language: 80, guide: 85, preTravelInteraction: 60, customTour: 75, globalExpansion: 70 },
  ];

  const swotData = [
    { name: 'Strengths', value: 30 },
    { name: 'Weaknesses', value: 20 },
    { name: 'Opportunities', value: 35 },
    { name: 'Threats', value: 15 },
  ];

  const financialForecastData = [
    { year: 2024, revenue: 100, expenses: 120, profit: -20 },
    { year: 2025, revenue: 300, expenses: 250, profit: 50 },
    { year: 2026, revenue: 700, expenses: 500, profit: 200 },
    { year: 2027, revenue: 1500, expenses: 1000, profit: 500 },
  ];

  const userPersonaData = [
    { attribute: 'Age', value: 25 },
    { attribute: 'Travel Frequency', value: 3 },
    { attribute: 'Language Proficiency', value: 4 },
    { attribute: 'Tech Savviness', value: 8 },
    { attribute: 'Cultural Interest', value: 9 },
  ];

  const userGrowthData = [
    { month: 'Jan', users: 1000 },
    { month: 'Feb', users: 1500 },
    { month: 'Mar', users: 2000 },
    { month: 'Apr', users: 3000 },
    { month: 'May', users: 4000 },
    { month: 'Jun', users: 5500 },
  ];

  const drawerItems = [
    { text: t('marketAnalysis'), icon: <Dashboard />, value: 0 },
    { text: t('competitorAnalysis'), icon: <CompareArrows />, value: 1 },
    { text: t('swotAnalysis'), icon: <TrendingUp />, value: 2 },
    { text: t('financialForecast'), icon: <AttachMoney />, value: 3 },
    { text: t('userPersona'), icon: <Person />, value: 4 },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const appBar = document.querySelector('.MuiAppBar-root');
      if (appBar) {
        if (window.scrollY > 100) {
          appBar.style.background = 'rgba(63, 81, 181, 0.8)';
        } else {
          appBar.style.background = 'transparent';
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ flexGrow: 1, minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={toggleDrawer}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: 600 }}>
              {t('title')}
            </Typography>
            <IconButton color="inherit" onClick={toggleDarkMode}>
              {darkMode ? <Brightness7 /> : <Brightness4 />}
            </IconButton>
            <IconButton color="inherit" onClick={handlePrint}>
              <PrintIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Drawer
          variant="temporary"
          open={drawerOpen}
          onClose={toggleDrawer}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
          }}
        >
          <List>
            {drawerItems.map((item) => (
              <ListItem button key={item.text} onClick={() => { setTabValue(item.value); toggleDrawer(); }}>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </Drawer>

        <Container maxWidth="lg" sx={{ mt: 10, mb: 4, flexGrow: 1 }}>
          <AnimatedTypography
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Typography variant="h2" gutterBottom>
              {t('iRUTOMOGrowthStrategy')}
            </Typography>
          </AnimatedTypography>

          <Tabs 
            value={tabValue} 
            onChange={handleTabChange} 
            centered 
            variant={isMobile ? "scrollable" : "standard"} 
            scrollButtons="auto"
            sx={{ mb: 3, display: { xs: 'none', sm: 'flex' } }}
          >
            {drawerItems.map((item) => (
              <Tab key={item.text} label={item.text} icon={item.icon} />
            ))}
          </Tabs>

          <AnimatePresence mode="wait">
            <motion.div
              key={tabValue}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5 }}
            >
              <Box mt={3}>
                {tabValue === 0 && (
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <StyledPaper elevation={3}>
                        <Typography variant="h5" gutterBottom>{t('visitorTrend')}</Typography>
                        <ResponsiveContainer width="100%" height={300}>
                          <LineChart data={visitorData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="year" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="visitors" stroke="#3f51b5" strokeWidth={2} />
                          </LineChart>
                        </ResponsiveContainer>
                      </StyledPaper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <StyledPaper elevation={3}>
                        <Typography variant="h5" gutterBottom>{t('marketSize')}</Typography>
                        <ResponsiveContainer width="100%" height={300}>
                          <BarChart data={marketSizeData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="year" />
                            <YAxis yAxisId="left" orientation="left" stroke="#3f51b5" />
                            <YAxis yAxisId="right" orientation="right" stroke="#f50057" />
                            <Tooltip />
                            <Legend />
                            <Bar yAxisId="left" dataKey="global" fill="#3f51b5" name={t('globalMarket')} />
                            <Bar yAxisId="right" dataKey="japan" fill="#f50057" name={t('japanMarket')} />
                          </BarChart>
                        </ResponsiveContainer>
                      </StyledPaper>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledPaper elevation={3}>
                        <Typography variant="h5" gutterBottom>{t('marketAnalysisSummary')}</Typography>
                        <Typography variant="body1">
                          IRUTOMOは急成長する言語交換アプリ市場において、独自の「言語交換×旅行」というコンセプトで差別化を図っています。訪日韓国人数の回復と共に、当社のサービス需要も高まると予測されます。グローバル市場の拡大に伴い、日本市場も着実に成長しており、IRUTOMOの成長機会は豊富に存在します。
                        </Typography>
                      </StyledPaper>
                    </Grid>
                  </Grid>
                )}

{tabValue === 1 && (
  <Grid container spacing={3}>
    <Grid item xs={12}>
      <StyledPaper elevation={3}>
        <Typography variant="h5" gutterBottom>{t('competitorAnalysis')}</Typography>
        <ResponsiveContainer width="100%" height={400}>
          <RadarChart data={competitorData}>
            <PolarGrid />
            <PolarAngleAxis dataKey="name" />
            <PolarRadiusAxis angle={30} domain={[0, 100]} />
            <Radar name="IRUTOMO" dataKey="language" stroke="#3f51b5" fill="#3f51b5" fillOpacity={0.6} />
            <Radar name="Competitor A" dataKey="guide" stroke="#f50057" fill="#f50057" fillOpacity={0.6} />
            <Radar name="Competitor B" dataKey="preTravelInteraction" stroke="#ffeb3b" fill="#ffeb3b" fillOpacity={0.6} />
            <Legend />
          </RadarChart>
        </ResponsiveContainer>
      </StyledPaper>
    </Grid>
    <Grid item xs={12}>
      <StyledPaper elevation={3}>
        <Typography variant="h5" gutterBottom>{t('competitorAnalysisSummary')}</Typography>
        <Typography variant="body1">
          IRUTOMOは言語交換機能と旅行前交流において強みを持っています。一方、グローバル展開ではCompetitor Aに後れを取っており、この領域での強化が必要です。ガイド機能ではCompetitor Bが優位ですが、IRUTOMOのカスタムツアー機能で差別化を図ることができます。総合的に見て、IRUTOMOは独自のポジショニングを確立していますが、各領域でさらなる改善の余地があります。
        </Typography>
      </StyledPaper>
    </Grid>
  </Grid>
)}

                {tabValue === 2 && (
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <StyledPaper elevation={3}>
                        <Typography variant="h5" gutterBottom>{t('swotAnalysis')}</Typography>
                        <ResponsiveContainer width="100%" height={300}>
                          <PieChart>
                            <Pie dataKey="value" data={swotData} fill="#3f51b5" label />
                            <Tooltip />
                          </PieChart>
                        </ResponsiveContainer>
                      </StyledPaper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <StyledPaper elevation={3}>
                        <Typography variant="h5" gutterBottom>{t('swotAnalysisSummary')}</Typography>
                        <Typography variant="body1">
                          <strong>強み：</strong>言語交換と旅行の融合、MZ世代へのフォーカス<br/>
                          <strong>弱み：</strong>グローバル展開の遅れ、ブランド認知度の低さ<br/>
                          <strong>機会：</strong>インバウンド需要の回復、言語学習アプリ市場の成長<br/>
                          <strong>脅威：</strong>競合の参入、為替変動、地政学的リスク<br/>
                          IRUTOMOは独自のコンセプトと若年層へのアプローチを強みとしていますが、グローバル展開とブランド認知度の向上に課題があります。インバウンド需要の回復と言語学習市場の成長は大きな機会となりますが、競合の参入や外部環境の変化にも注意が必要です。
                        </Typography>
                      </StyledPaper>
                    </Grid>
                  </Grid>
                )}

                {tabValue === 3 && (
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <StyledPaper elevation={3}>
                        <Typography variant="h5" gutterBottom>{t('financialForecast')}</Typography>
                        <ResponsiveContainer width="100%" height={300}>
                          <LineChart data={financialForecastData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="year" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="revenue" stroke="#3f51b5" />
                            <Line type="monotone" dataKey="expenses" stroke="#f50057" />
                            <Line type="monotone" dataKey="profit" stroke="#4caf50" />
                          </LineChart>
                        </ResponsiveContainer>
                      </StyledPaper>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledPaper elevation={3}>
                        <Typography variant="h5" gutterBottom>{t('financialForecastSummary')}</Typography>
                        <Typography variant="body1">
                          IRUTOMOの財務予測では、初年度は投資フェーズのため赤字となりますが、2025年には黒字転換し、その後急速な成長が見込まれます。2027年には収益15億円、利益5億円を目指します。この成長を実現するためには、ユーザー獲得コストの最適化、収益モデルの多様化、運営効率の向上が鍵となります。また、段階的な資金調達を行い、成長投資と財務健全性のバランスを取ることが重要です。
                        </Typography>
                      </StyledPaper>
                    </Grid>
                  </Grid>
                )}

                {tabValue === 4 && (
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <StyledPaper elevation={3}>
                        <Typography variant="h5" gutterBottom>{t('userPersona')}</Typography>
                        <ResponsiveContainer width="100%" height={300}>
                          <RadarChart data={userPersonaData}>
                            <PolarGrid />
                            <PolarAngleAxis dataKey="attribute" />
                            <PolarRadiusAxis angle={30} domain={[0, 10]} />
                            <Radar name="User Persona" dataKey="value" stroke="#3f51b5" fill="#3f51b5" fillOpacity={0.6} />
                          </RadarChart>
                        </ResponsiveContainer>
                      </StyledPaper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <StyledPaper elevation={3}>
                        <Typography variant="h5" gutterBottom>{t('userPersonaSummary')}</Typography>
                        <Typography variant="body1">
                          IRUTOMOの主要ターゲットユーザーは、以下の特徴を持つMZ世代です：
                          <ul>
                            <li>年齢：20代半ば（平均25歳）</li>
                            <li>年間海外旅行回数：2-3回</li>
                            <li>言語学習への意欲：高い（5段階中4）</li>
                            <li>テクノロジーへの親和性：非常に高い（10段階中8）</li>
                            <li>異文化への興味：非常に高い（10段階中9）</li>
                          </ul>
                          このペルソナに基づき、言語学習と旅行体験を融合させた機能開発、直感的なUI/UXデザイン、文化交流を促進するコンテンツ提供が重要となります。また、SNSとの連携やゲーミフィケーション要素の導入も効果的でしょう。
                        </Typography>
                      </StyledPaper>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledPaper elevation={3}>
                        <Typography variant="h5" gutterBottom>{t('userGrowthTrend')}</Typography>
                        <ResponsiveContainer width="100%" height={300}>
                          <AreaChart data={userGrowthData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis />
                            <Tooltip />
                            <Area type="monotone" dataKey="users" stroke="#3f51b5" fill="#3f51b5" />
                          </AreaChart>
                        </ResponsiveContainer>
                      </StyledPaper>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </motion.div>
          </AnimatePresence>

          <Box mt={5}>
            <AnimatedTypography
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <Typography variant="h4" gutterBottom>{t('growthStrategy')}</Typography>
            </AnimatedTypography>
            <Typography variant="body1">
              IRUTOMOの成長戦略は以下の4つの柱に基づいています：
              
              1. プロダクト強化：
              - 言語交換機能の拡充（AIを活用したマッチング、レベル別学習コンテンツ）
              - 旅行プランニング機能の高度化（AR/VRを活用した事前観光体験）
              - ユーザー生成コンテンツの促進（旅行記、言語学習Tips）

              2. ユーザー獲得：
              - インフルエンサーマーケティングの強化（韓国の人気YouTuber、旅行ブロガーとの提携）
              - 大学や語学学校とのパートナーシップ（留学生向けプログラムの提供）
              - オフラインイベントの開催（言語交換会、文化交流フェスティバル）

              3. グローバル展開：
              - 韓国市場での地位確立後、中国、東南アジア市場への展開
              - 現地企業とのアライアンスによる迅速な市場参入
              - 多言語対応（英語、中国語、その他アジア言語）

              4. 収益モデルの多様化：
              - プレミアム会員制度の導入（広告非表示、高度な学習ツール）
              - 旅行関連サービスとの連携（ホテル予約、ツアー予約での手数料）
              - 企業向けソリューションの開発（インバウンド対応研修、社員の語学研修）

              これらの戦略を段階的に実行し、2027年までにMAU 500万人、年間売上高100億円の達成を目指します。
            </Typography>
          </Box>

          <Box mt={5}>
            <AnimatedTypography
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <Typography variant="h4" gutterBottom>{t('conclusion')}</Typography>
            </AnimatedTypography>
            <Typography variant="body1">
              IRUTOMOは、「言語交換×旅行」という独自のコンセプトと、MZ世代に特化したアプローチにより、急成長する言語学習アプリ市場と回復基調にあるインバウンド市場の両方で大きな可能性を秘めています。
              
              市場分析、競合分析、SWOT分析の結果から、IRUTOMOの強みを活かしつつ、グローバル展開やブランド認知度の向上など、課題に取り組む必要があります。財務予測に示される通り、初期の投資フェーズを経て、2025年以降の急成長が期待されます。

              ユーザーペルソナに基づいた製品開発とマーケティング戦略の実行、そして4つの柱からなる成長戦略の着実な遂行により、IRUTOMOは言語学習と旅行の未来を変革する存在となる可能性を秘めています。

              今後は、テクノロジーの進化や市場動向を注視しつつ、ユーザーニーズに柔軟に対応し、継続的なイノベーションを行うことで、持続的な成長を実現していきます。
            </Typography>
          </Box>
        </Container>

        <FloatingFab color="primary" aria-label="scroll to top" onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
          <ArrowUpward />
        </FloatingFab>
      </Box>
    </ThemeProvider>
  );
}

export default IRUTOMOReport;